import React from "react";
import {
  ImageWrapper,
  CopyWrapper,
  ServiceTitle,
  ServiceDescription,
  Wrapper,
  CaseStudy,
} from "./styled";
import Image from "../../modules/Image";
import { ServiceModel } from "../Services";
import { useInView } from "react-hook-inview";
import { fade } from "../../../transition";

const Service = ({
  title,
  image,
  description,
  caseStudy,
  links,
}: ServiceModel) => {
  const [ref, isVisible] = useInView({
    unobserveOnEnter: true,
    threshold: 0.5,
  });

  let url = undefined;

  if (!!caseStudy) {
    const link = links.filter(link => link.title === caseStudy)[0];
    if (!!link) {
      url = link.url;
    }
  }

  return (
    <Wrapper ref={ref} isVisibile={isVisible}>
      <ImageWrapper>
        <Image image={image} altText={title} />
      </ImageWrapper>
      <CopyWrapper>
        <ServiceTitle>{title}</ServiceTitle>
        <ServiceDescription>{description}</ServiceDescription>
        {!!url && (
          <CaseStudy
            exit={{
              length: 0.25,
              trigger: ({ exit, node }) =>
                fade({ exit, node, direction: "out" }),
            }}
            entry={{
              length: 0.25,
              trigger: ({ exit, node }) =>
                fade({ exit, node, direction: "in" }),
              delay: 0.25,
            }}
            to={url}
          >
            See case study
          </CaseStudy>
        )}
      </CopyWrapper>
    </Wrapper>
  );
};

export default Service;
