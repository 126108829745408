import React, { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Color } from "../../../settings";
import { FocalPointType } from "../../../types";
import { FOCAL_POINT_TO_PERCENTAGE } from "../../../mapping";

interface NoB64Props {
  image: string;
  size?: "full" | "half";
  altText: string;
  style?: React.CSSProperties;
  backgroundColor: string;
  focalPoint: FocalPointType;
  customFocalPoint?: string;
}

const NoB64 = ({
  image,
  size,
  style,
  backgroundColor,
  focalPoint,
  customFocalPoint,
  ...props
}: NoB64Props) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { in: ["jpg", "png"] } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 2560, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `);

  const match = useMemo(
    () =>
      data.allFile.edges.find(
        ({ node }) => image.split("/")[2] === node.relativePath
      ),
    [data, image]
  );

  if (!!match) {
    return (
      <Img
        fluid={match.node.childImageSharp.fluid}
        backgroundColor={backgroundColor}
        style={{ ...style }}
        imgStyle={{ objectPosition: !!customFocalPoint ? customFocalPoint : FOCAL_POINT_TO_PERCENTAGE[focalPoint] ?? '50% 50%' }}
        {...props}
      />
    );
  }

  return null;
};

export default NoB64;
