import styled from "styled-components";
import { Color, Space, Weight, Breakpoints, Easing } from "../../../settings";
import { breakpointUp, breakpointDown } from "../../../util/breakpoint";
import { SERVICES_BREAKPOINT } from "../Services/styled";
import TransitionLink from "gatsby-plugin-transition-link";
import { LINK_UNDERLINE_STYLE } from "../../core/Navigation/styled";

export const ImageWrapper = styled.figure`
  margin: 0 auto;
  padding: 0;

  ${breakpointUp(SERVICES_BREAKPOINT.upper)`
    width: 60%;
  `}
`;

export const CopyWrapper = styled.div`
  ${breakpointDown(SERVICES_BREAKPOINT.lower)`
    margin-top: ${Space.large.rem};
    text-align: center;
  `}

  ${breakpointUp(SERVICES_BREAKPOINT.upper)`
    width: calc(40% - ${Space.xxLarge.rem});
  `}
`;

interface WrapperProps {
  isVisibile: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  transform: ${p =>
    p.isVisibile ? `translateY(0)` : `translateY(${Space.large.rem})`};
  opacity: ${p => (p.isVisibile ? 1 : 0)};
  transition: opacity ${Easing.default}, transform ${Easing.default};

  ${breakpointDown(SERVICES_BREAKPOINT.lower)`
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  `}

  & + & {
    margin-top: ${Space.xxLarge.rem};
  }

  ${breakpointUp(SERVICES_BREAKPOINT.upper)`
    display: flex;
    align-items: center;

    &:nth-child(odd) {
      ${ImageWrapper} {
        margin-right: ${Space.xxLarge.rem};
      }
    }

    &:nth-child(even) {
      ${ImageWrapper} {
        order: 2;
        margin-left: ${Space.xxLarge.rem};
      }
    }
  `}
`;

export const ServiceTitle = styled.h2`
  margin: 0;
  margin-bottom: ${Space.default.rem};

  font-weight: ${Weight.regular};
`;

export const ServiceDescription = styled.p`
  margin: 0;
`;

export const CaseStudy = styled(TransitionLink)`
  position: relative;
  display: inline-block;

  margin-top: ${Space.default.rem};

  color: ${Color.white};
  ${LINK_UNDERLINE_STYLE};

  &:after {
    transform: scaleX(1);
    opacity: 1;
  }

  &:hover {
    &:after {
      transform: scaleX(0);
      opacity: 0;
    }
  }
`;
