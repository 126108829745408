import styled from "styled-components";
import { breakpointUp, breakpoint } from "../../../util/breakpoint";
import { Breakpoints, Weight, Space, Easing } from "../../../settings";

export const THREE_COL = Breakpoints.tabletPortrait;
export const TWO_COL = Breakpoints.mobileLarge;

export const OuterWrapper = styled.div`
  margin-bottom: ${Space.xxLarge.rem};

  ${breakpointUp(Breakpoints.desktop.upper)`
    margin-bottom: ${Space.xxxLarge.rem};
  `}
`;

export const Title = styled.h2<ProcessItemProps>`
  margin-top: 0;
  margin-bottom: ${Space.xLarge.rem};

  opacity: ${p => (p.isVisible ? 1 : 0)};
  transition: opacity ${Easing.default};

  text-align: center;
`;

export const Wrapper = styled.ol`
  margin: 0;
  padding: 0;

  ${breakpointUp(TWO_COL.upper)`
    display: flex;
    flex-wrap: wrap;
  `}
`;

interface ProcessItemProps {
  isVisible: boolean;
}

export const ProcessItem = styled.li<ProcessItemProps>`
  margin-top: ${Space.xLarge.rem};

  list-style: none;
  opacity: ${p => (p.isVisible ? 1 : 0)};
  transform: ${p =>
    p.isVisible ? `translateY(0)` : `translateY(${Space.default.rem})`};
  transition: opacity ${Easing.slow}, transform ${Easing.default};

  ${breakpoint(TWO_COL.upper, THREE_COL.lower)`
    width: calc(50% - ${Space.xLarge.number / 2}rem);
    margin-right: ${Space.xLarge.rem};

    &:nth-child(-n + 2) {
      margin-top: 0;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  `}

  ${breakpointUp(THREE_COL.upper)`
    width: calc(33.32% - ${(Space.xxxLarge.number * 2) / 3}rem);
    margin-right: ${Space.xxxLarge.rem};

    &:nth-child(-n + 3) {
      margin-top: 0;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  `}
`;

export const ProcessTitle = styled.h3`
  font-weight: ${Weight.regular};
  margin-top: 0;
  margin-bottom: ${Space.large.rem};
`;

export const ProcessDescription = styled.p`
  margin: 0;
`;
