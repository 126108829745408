import React, { useRef, useEffect, useState } from "react";
import { Header, Title, ArrowWrapper, Arrow, VideoTransition } from "./styled";
import { Container } from "../../core/Container/styled";

import { Color } from "../../../settings";
import ArrowDown from "../../icon/ArrowDown";
import { smoothScrollTo } from "../../../util/scrollTo";
import NoB64 from "../Image/NoB64";
import { FocalPointType } from "../../../types";
import Vimeo from "@u-wave/react-vimeo";

interface BannerProps {
  videoId?: string;
  image: string;
  focalPoint: FocalPointType;
  customFocalPoint?: string;
  altText: string;
  backgroundColor: string;
}

const Banner = ({
  image,
  altText,
  backgroundColor,
  focalPoint,
  customFocalPoint,
  videoId,
}: BannerProps) => {
  const ref = useRef<HTMLElement>(null);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);

  useEffect(() => {
    setHeaderHeight(ref.current.clientHeight);
  }, [ref]);

  return (
    <Header ref={ref}>
      {!!videoId ? (
        <>
          <VideoTransition isLoaded={isVideoLoaded}>
            <Vimeo
              video={videoId}
              autoplay={true}
              controls={false}
              muted={true}
              loop={true}
              responsive={false}
              id="vimeo-wrapper"
              onLoaded={() => setIsVideoLoaded(true)}
            />
          </VideoTransition>
          <NoB64
            image={image}
            focalPoint={focalPoint}
            altText={altText}
            style={{
              width: "100vw",
              height: "56.25vw",
              minHeight: "100vh",
              minWidth: "177.77vh",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translateX(-50%) translateY(-50%)",
            }}
            backgroundColor={backgroundColor}
          />
        </>
      ) : (
        <NoB64
          image={image}
          focalPoint={focalPoint}
          customFocalPoint={customFocalPoint}
          altText={altText}
          style={{
            objectFit: "fill",
            width: "100%",
            height: "100%",
          }}
          backgroundColor={backgroundColor}
        />
      )}
      <ArrowWrapper onClick={() => smoothScrollTo(headerHeight)}>
        <Arrow>
          <ArrowDown color={Color.white} />
        </Arrow>
      </ArrowWrapper>
    </Header>
  );
};

export default Banner;
