import styled from "styled-components";
import {
  breakpoint,
  breakpointUp,
  breakpointDown,
} from "../../../util/breakpoint";
import { Space, Easing } from "../../../settings";
import { createBreakpoint } from "../../../util/createBreakpoint";

const TWO_COL = createBreakpoint(720);
const THREE_COL = createBreakpoint(960);
const FIVE_COL = createBreakpoint(1440);

export const Wrapper = styled.div`
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

interface ClientWrapperProps {
  isVisible: boolean;
}

export const ClientWrapper = styled.article<ClientWrapperProps>`
  display: flex;
  align-items: center;
  margin-top: ${Space.xLarge.rem};
  opacity: ${p => (p.isVisible ? 1 : 0)};
  transform: ${p =>
    p.isVisible ? `translateY(0)` : `translateY(${Space.default.rem})`};
  transition: opacity ${Easing.default}, transform ${Easing.default};

  ${breakpointDown(TWO_COL.lower)`
    width: calc(50% - ${Space.xxxLarge.number / 2}rem);
    margin-right: ${Space.xxxLarge.rem};

    &:nth-child(-n + 2) {
      margin-top: 0;
    }

    &:nth-child(2n),
    &:last-child {
      margin-right: 0;
    }
  `}

  ${breakpoint(TWO_COL.upper, THREE_COL.lower)`
    width: calc(33.32% - ${(Space.xxxLarge.number * 2) / 3}rem);
    margin-right: ${Space.xxxLarge.rem};

    &:nth-child(-n + 3) {
      margin-top: 0;
    }

    &:nth-child(3n),
    &:last-child {
      margin-right: 0;
    }
  `}

  ${breakpoint(THREE_COL.upper, FIVE_COL.lower)`
    width: calc(25% - ${(Space.xxxLarge.number * 3) / 4}rem);
    margin-right: ${Space.xxxLarge.rem};

    &:nth-child(-n + 4) {
      margin-top: 0;
    }

    &:nth-child(4n),
    &:last-child {
      margin-right: 0;
    }
  `}

  ${breakpointUp(FIVE_COL.upper)`
    width: calc(16.65% - ${(Space.xxxLarge.number * 5) / 6}rem);
    margin-right: ${Space.xxxLarge.rem};

    &:nth-child(-n + 6) {
      margin-top: 0;
    }

    &:nth-child(6n),
    &:last-child {
      margin-right: 0;
    }
  `}
`;

export const Image = styled.img`
  width: 100%;
  vertical-align: bottom;
  filter: grayscale(1) contrast(10);
`;

export const Link = styled.a`
  display: block;

  width: 100%;
`;
