import styled from "styled-components";
import { Color, Space, Breakpoints, Easing } from "../../../settings";
import { breakpointUp } from "../../../util/breakpoint";

export const Wrapper = styled.div`
  padding: ${Space.xLarge.rem} 0;
  margin-bottom: ${Space.xxLarge.rem};
  width: calc(100% - ${Space.large.number * 2}rem);
  margin-left: ${Space.large.rem};
  overflow: hidden;

  ${breakpointUp(Breakpoints.desktop.upper)`
    margin-bottom: ${Space.xxxLarge.rem};
    padding: ${Space.xxLarge.rem};

    width: calc(100% - ${Space.xLarge.number * 2}rem);
    margin-left: ${Space.xLarge.rem};
  `}

  background-color: ${Color.lightBuff};
  text-align: center;
`;

export const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  left: 50%;

  transition: transform ease 0.75s, opacity ${Easing.xSlow};
`;

interface ItemProps {
  isActive: boolean;
}

export const Item = styled.div<ItemProps>`
  opacity: ${p => (p.isActive ? 1 : 0)};
  transition: opacity 1s ease;
  width: 100%;
  padding: 0 ${Space.large.rem};
  flex-shrink: 0;
`;

export const Title = styled.h2`
  margin-bottom: ${Space.xxLarge.rem};
  margin-top: 0;
`;

export const Bullets = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: ${Space.xxLarge.rem};
`;

interface BulletProps {
  isActive: boolean;
}

export const Bullet = styled.button<BulletProps>`
  width: ${Space.default.rem};
  height: ${Space.default.rem};

  background: none;
  border: none;
  border-radius: ${Space.default.rem};
  background-color: ${p => (p.isActive ? Color.black : Color.white)};
  cursor: ${p => (p.isActive ? "default" : "pointer")};
  transition: background-color ${Easing.default};

  & + & {
    margin-left: ${Space.small.rem};
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${p => (p.isActive ? Color.black : Color.darkBuff)};
  }
`;
