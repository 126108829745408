import React from "react";

import { useInView } from "react-hook-inview";
import { Title } from "../Process/styled";
import { Wrapper, ClientWrapper, Image, Link } from "./styled";
import { externaliseLink } from "../../../util/externaliseLink";

export interface ClientModel {
  clientName: string;
  clientLogo: string;
  clientLink?: string;
}

interface ClientsProps {
  title: string;
  logos: ClientModel[];
}

const Clients = ({ title, logos }: ClientsProps) => {
  const [ref, isVisible] = useInView({
    unobserveOnEnter: true,
    threshold: 0.5,
  });
  return (
    <div ref={ref}>
      <Title isVisible={isVisible}>{title}</Title>
      <Wrapper>
        {logos.map(logo => (
          <ClientWrapper
            isVisible={isVisible}
            key={`client-${logo.clientName}`}
          >
            {!!logo.clientLink ? (
              <Link href={externaliseLink(logo.clientLink)}>
                <Image src={logo.clientLogo} alt={logo.clientName} />
              </Link>
            ) : (
              <Image src={logo.clientLogo} alt={logo.clientName} />
            )}
          </ClientWrapper>
        ))}
      </Wrapper>
    </div>
  );
};

export default Clients;
