import React from "react";
import { graphql, Link } from "gatsby";
import { GraphQLResponse } from "../models";
import { getFrontMatter } from "../util";
import { Layout } from "../layouts";
import { Container } from "../components/core/Container/styled";
import PageHeader from "../components/core/PageHeader";
import Process, { ProcessItemModel } from "../components/studio/Process";
import { Color } from "../settings";
import Services, { ServiceModel } from "../components/studio/Services";
import Clients from "../components/studio/Clients";
import Title from "../components/core/Intro";
import Banner from "../components/modules/Banner";
import Intro from "../components/core/Intro";
import { FocalPointType } from "../types";
import Quote from "../components/core/Quote";
import Testimonials from "../components/studio/Testimonials";

interface StudioProps {
  title: string;
  description: string;
  banner: {
    image: string;
    focalPoint: FocalPointType;
    customFocalPoint?: string;
  };
  subtitle: string;
  process: ProcessItemModel[];
  services: ServiceModel[];
  clients: {
    title: string;
    logos: [];
  };
}

interface StudioPageProps extends GraphQLResponse<StudioProps> {}

const StudioPage = (props: StudioPageProps) => {
  const {
    title,
    description,
    process,
    services,
    subtitle,
    clients,
    banner,
  } = getFrontMatter(props);

  const testimonials = props.data.testimonials.edges.map(
    edge => edge.node.frontmatter
  );

  const caseStudyLinks = props.data.allMarkdownRemark.edges.map(edge => ({
    title: edge.node.frontmatter.title,
    url: edge.node.fields.slug,
  }));

  return (
    <Layout
      title={title}
      headerColor={Color.white}
      headerBackgroundColor={Color.mutedPine}
    >
      <Banner
        image={banner.image}
        altText={title}
        backgroundColor={Color.darkenedPine}
        focalPoint={banner.focalPoint}
        customFocalPoint={banner.customFocalPoint}
      />
      <Intro
        title={title}
        description={description}
        backgroundColor={Color.mutedPine}
        color={Color.white}
      />
      <Services
        subtitle={subtitle}
        services={services}
        links={caseStudyLinks}
      />
      <Container size="medium">
        <Process items={process} />
      </Container>
      <Testimonials testimonials={testimonials} />
      <Container>
        <Clients title={clients.title} logos={clients.logos} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    allFile(
      filter: { sourceInstanceName: { eq: "content" }, name: { eq: "studio" } }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              description
              banner {
                image
                focalPoint
                customFocalPoint
              }
              subtitle
              process {
                title
                description
              }
              services {
                title
                description
                image
                caseStudy
              }
              clients {
                title
                logos {
                  clientLink
                  clientLogo
                  clientName
                }
              }
            }
          }
        }
      }
    }

    testimonials: allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "testimonial" } } }
    ) {
      edges {
        node {
          frontmatter {
            quote
            attribution
            company
          }
        }
      }
    }

    allMarkdownRemark(filter: { frontmatter: { layout: { eq: "project" } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;

export default StudioPage;
