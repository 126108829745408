import React, { useState } from "react";
import Quote from "../../core/Quote";
import { InnerWrapper, Item, Wrapper, Title, Bullets, Bullet } from "./styled";
import { useMediaQuery } from "react-responsive";
import { Breakpoints } from "../../../settings";
import { useInView } from "react-hook-inview";

interface TestimonialsProps {
  testimonials: any;
}

const Testimonials = ({ testimonials }: TestimonialsProps) => {
  const [current, setCurrent] = useState<number>(0);
  const [ref, isVisible] = useInView({
    unobserveOnEnter: true,
    threshold: 1,
  });

  const isWide = useMediaQuery({ minWidth: Breakpoints.desktopLarge.upper });
  let vw = isWide ? 33 : 85;
  const width = 100 / testimonials.length;
  const transform = width * current + width / 2;

  return (
    <Wrapper>
      <Title ref={ref}>What they say</Title>
      <InnerWrapper
        style={{
          width: `${vw * testimonials.length}vw`,
          transform: `translateX(-${transform}%)`,
          opacity: isVisible ? 1 : 1,
        }}
      >
        {testimonials.map((testimonial, index) => (
          <Item
            key={`testimonial-${index}`}
            isActive={index === current}
            style={{ width: `${vw}vw` }}
          >
            <Quote
              quote={testimonial.quote}
              attribution={testimonial.attribution}
              company={testimonial.company}
              isFull={true}
              shrink={true}
            />
          </Item>
        ))}
      </InnerWrapper>
      <Bullets>
        {testimonials.map((testimonial, index) => (
          <Bullet
            key={`bullet-${index}`}
            isActive={index === current}
            onClick={() => setCurrent(index)}
          />
        ))}
      </Bullets>
    </Wrapper>
  );
};

export default Testimonials;
