import React from "react";
import {
  Wrapper,
  ProcessItem,
  ProcessTitle,
  ProcessDescription,
  Title,
  OuterWrapper,
} from "./styled";
import { useInView } from "react-hook-inview";
import { Space } from "../../../settings";

export interface ProcessItemModel {
  title: string;
  description: string;
}

interface ProcessProps {
  items: ProcessItemModel[];
}

const Process = ({ items }: ProcessProps) => {
  const [ref, isVisible] = useInView({
    unobserveOnEnter: true,
    threshold: 0.5,
  });
  return (
    <OuterWrapper ref={ref}>
      <Title isVisible={isVisible}>Process</Title>
      <Wrapper>
        {items.map((item, index) => (
          <ProcessItem isVisible={isVisible} key={`process-${index}`}>
            <ProcessTitle>
              {index + 1}. {item.title}
            </ProcessTitle>
            <ProcessDescription>{item.description}</ProcessDescription>
          </ProcessItem>
        ))}
      </Wrapper>
    </OuterWrapper>
  );
};

export default Process;
