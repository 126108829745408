import React from "react";
import { Container } from "../Container/styled";
import { Wrapper, StyledTitle, Description } from "./styled";

interface IntroProps {
  title: string;
  description?: string;
  color?: string;
  backgroundColor?: string;
}

const Intro = ({ title, color, backgroundColor, description }: IntroProps) => {
  return (
    <Wrapper backgroundColor={backgroundColor}>
      <Container size="small">
        <StyledTitle color={color}>{title}</StyledTitle>
      </Container>
      {!!description && (
        <Container size="small">
          <Description color={color}>{description}</Description>
        </Container>
      )}
    </Wrapper>
  );
};

export default Intro;
