import styled from "styled-components";
import {
  Color,
  Space,
  Easing,
  Typography,
  Breakpoints,
} from "../../../settings";

export const Header = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: calc(100vh - 103px);
  overflow: hidden;
  color: ${Color.white};
  background-size: cover;

  #vimeo-wrapper {
    background-color: ${Color.concrete};
    height: 100%;
    width: 100%;
  }

  iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

interface VideoTransitionProps {
  isLoaded: boolean;
}

export const VideoTransition = styled.div<VideoTransitionProps>`
  background-color: ${Color.darkConcrete};
  height: 100%;
  width: 100%;

  iframe {
    opacity: ${p => (p.isLoaded ? 1 : 0)};
    z-index: 1;
  }
`;

export const Title = styled.h1`
  position: relative;

  text-align: center;
  font-weight: 600;
  z-index: 1;
`;

export const Arrow = styled.span`
  display: inline-block;
  width: 16px;

  color: ${Color.white};
  font-size: ${Typography.xLarge.rem};
  text-decoration: none;
  transition: transform ${Easing.slow}, opacity ${Easing.slow};

  .line {
    transform: scaleY(0.6);
    transition: transform ${Easing.slow};
    transform-origin: 0 100%;
  }
`;

export const ArrowWrapper = styled.span`
  position: absolute;

  bottom: ${Space.xLarge.rem};
  left: 50%;
  width: ${Space.xLarge.rem};
  z-index: 5;

  cursor: pointer;
  text-align: center;
  transform: translateX(-50%);

  &:hover {
    ${Arrow} {
      transform: translateY(10px);
      .line {
        transform: scaleY(1);
      }
    }
  }
`;
