import React from "react";
import { IconProps } from "../../models";

const ArrowDown = ({ color }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.53 42.26">
    <polyline
      points="10.53 37 5.27 42.26 0 37"
      fill={color}
      className="arrowhead"
    />
    <line
      x1="5.27"
      y1="38"
      x2="5.27"
      fill="none"
      stroke={color}
      className="line"
    />
  </svg>
);

export default ArrowDown;
