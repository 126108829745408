import styled from "styled-components";
import { Color, Space, Breakpoints } from "../../../settings";
import { breakpointUp } from "../../../util/breakpoint";

export const SERVICES_BREAKPOINT = Breakpoints.tabletPortrait;

export const Wrapper = styled.div`
  padding-bottom: ${Space.xxLarge.rem};
  margin-bottom: ${Space.xxLarge.rem};

  ${breakpointUp(Breakpoints.desktop.upper)`
    padding-bottom: ${Space.xxxLarge.rem};
    margin-bottom: ${Space.xxxLarge.rem};
  `}

  background-color: ${Color.mutedPine};
  color: ${Color.white};
`;

export const Subtitle = styled.h2`
  margin-top: 0;
  margin-bottom: ${Space.xxLarge.rem};
  // padding-top: ${Space.xLarge.rem};

  text-align: center;
`;
