import styled from "styled-components";
import { Typography, Space, Color, Breakpoints, Weight } from "../../../settings";
import { breakpointUp } from "../../../util/breakpoint";

interface WrapperProps {
  backgroundColor?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  padding: ${Space.xxLarge.rem} 0;

  background-color: ${p => p.backgroundColor ?? Color.white};

  ${breakpointUp(Breakpoints.desktop.upper)`
    padding: ${Space.xxxLarge.rem} 0;
  `}
`;

interface TitleProps {
  color: string;
}

export const StyledTitle = styled.h1<TitleProps>`
  margin: 0;

  color: ${p => p.color ?? Color.black};
  font-size: ${Typography.xLarge.rem};
  text-align: center;
`;

export const Description = styled.p<TitleProps>`
  margin-top: ${Space.xxLarge.rem};

  color: ${p => p.color ?? Color.black};
  font-weight: ${Weight.regular};
  font-size: ${Typography.default.rem};
  font-weight: ${Weight.regular};
  line-height: 120%;

  ${breakpointUp(Breakpoints.desktop.upper)`
    // column-count: 2;
    // column-gap: ${Space.xxLarge.number * 2}rem;
  `}
`;