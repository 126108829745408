import React from "react";
import { Container } from "../../core/Container/styled";
import { Wrapper, Subtitle } from "./styled";
import Service from "../Service";

export interface ServiceModel {
  title: string;
  description: string;
  image: string;
  caseStudy?: string;
  links: CaseStudyLinkModel[];
}

export interface CaseStudyLinkModel {
  title: string;
  url: string;
}

interface ServicesProps {
  subtitle: string;
  services: ServiceModel[];
  links: CaseStudyLinkModel[];
}

const Services = ({ subtitle, services, links }: ServicesProps) => {
  return (
    <Wrapper>
      <Container size="medium">
        <Subtitle>{subtitle}</Subtitle>
        <div>
          {services.map((service, index) => (
            <Service
              key={`${service.title}-${index}`}
              {...service}
              links={links}
            />
          ))}
        </div>
      </Container>
    </Wrapper>
  );
};

export default Services;
